import Link from 'next/link';
import React from 'react';
import { baseUrl } from '../../../config/config';
import Image from 'next/image';

const NotFound = ({ message, link = null, linkText = "" }) => (
    <div className="empty-content-box">
        <div className="empty-content-box-icon position-relative">
            <Image loading="lazy" src={baseUrl + "/images/sad.png"} alt="" layout="fill" />
        </div>
        <div className="not-found-title">
            <h2><span>Opps!</span> Sorry</h2>
            <p>{message}</p>
        </div>
        {link &&
            <Link href={link}>
                <a className="btn btn-sm btn-dark">{linkText}</a>
            </Link>
        }
    </div>
)


export default NotFound;